import TwitterIcon from '@mui/icons-material/Twitter';
import { IconButton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { SidebarNav } from './components';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onClose: () => void;
  open: boolean;
  variant: 'permanent' | 'persistent' | 'temporary' | undefined;
  pages: {
    blockExplorer: Array<PageItem>;
    ftsoMonitor: Array<PageItem>;
    validators: Array<PageItem>;
  };
}

const Sidebar = ({ pages, open, variant, onClose }: Props): JSX.Element => {
  return (
    <Drawer
      anchor="left"
      onClose={() => onClose()}
      open={open}
      variant={variant}
      sx={{
        '& .MuiPaper-root': {
          width: '100%',
          maxWidth: 280,
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          padding: 1,
        }}
      >
        <SidebarNav pages={pages} />
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingX={2}
      >
        <IconButton
          aria-label="twitter-icon"
          sx={{ ml: 1, borderRadius: 5 }}
          onClick={() =>
            window.open('https://twitter.com/FlareDienst', '_blank')
          }
        >
          <Typography sx={{ mr: 2 }}>Follow us on Twitter</Typography>
          <TwitterIcon color="primary" />
        </IconButton>
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
        paddingX={2}
        paddingY={1}
      >
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; {new Date().getFullYear()}, Flare Dienst. All rights reserved
        </Typography>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
