import React from 'react';
import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { Home } from 'view';

const Routes = (): JSX.Element => {
  return (
    <ReactRoutes>
      <Route path="/" element={<Home/>}/>
    </ReactRoutes>
  );
};

export default Routes;
