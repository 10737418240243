import React from 'react';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { IconButton } from '@mui/material';

const ThemeModeToggler = (): JSX.Element => {
  const theme = useTheme();
  const { themeToggler } = theme;
  const { mode } = theme.palette;

  return (
    <IconButton
      aria-label="Dark mode toggler"
      onClick={() => themeToggler()}
      color={mode === 'light' ? 'primary' : 'secondary'}
    >
      {mode === 'light' ? <Brightness4Icon />: <Brightness7Icon color="primary"/>}
    </IconButton>
  );
};

export default ThemeModeToggler;
