import TwitterIcon from '@mui/icons-material/Twitter';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Footer = (): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <IconButton
            aria-label="twitter-icon"
            sx={{ ml: 1, borderRadius: 5 }}
            onClick={() =>
              window.open('https://twitter.com/FlareDienst', '_blank')
            }
          >
            <Typography sx={{ mr: 2, ml: 1 }}>Follow us on Twitter</Typography>
            <TwitterIcon color="primary" />
          </IconButton>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; {new Date().getFullYear()}, Flare Dienst. All rights reserved
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
