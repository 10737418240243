/* eslint-disable react/no-unescaped-entities */
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Illustration from 'assets/illustrations/illustration.svg';

const Hero = (): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Grid container spacing={4}>
      <Grid item container alignItems={'center'} xs={12} md={6}>
        <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h1"
              color="text.primary"
              sx={{
                fontWeight: 600,
              }}
            >
              Maximize Your Rewards with{' '}
              <Typography
                component={'span'}
                variant={'inherit'}
                color={'primary'}
                sx={{
                  background: `linear-gradient(180deg, transparent 86%, ${alpha(
                    theme.palette.secondary.main,
                    0.2,
                  )} 0%)`,
                }}
              >
                Flare Dienst
              </Typography>
            </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              sx={{ fontWeight: 400 }}
            >
              A thrustworthy infrastructure provider to the Flare Time Series Oracle
              (FTSO) on both Songbird and Flare. Delegate your WSGB or WFLR and
              earn rewards with{' '}
              <Link href="https://portal.flare.network/">Flare portal</Link> or{' '}
              <Link href="https://bifrostwallet.com/">Bifrost Wallet</Link>.
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box
          height={1}
          width={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Box height={1} width={1} maxWidth={400}>
            <Box
              component={'img'}
              src={Illustration}
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Hero;
