import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import ThemeModeToggler from 'components/ThemeModeToggler';
//@ts-ignore
import FlareDienst from 'assets/icons/FlareDienst';

import { Typography } from '@mui/material';
import { NavItem } from './components';

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSidebarOpen: () => void;
  pages: {
    blockExplorer: Array<PageItem>;
    ftsoMonitor: Array<PageItem>;
    validators: Array<PageItem>;
  };
  colorInvert?: boolean;
}

const Topbar = ({
  onSidebarOpen,
  pages,
  colorInvert = false,
}: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const { blockExplorer: blockExplorerPages, ftsoMonitor: ftsoMonitorPages, validators: validatorPages } =
    pages;

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <FlareDienst
          backgroundColor={mode === 'light' ? '#FFFFFF' : '#EEEEEF'}
          opacity={mode === 'light' ? 0 : 1}
        />
        <Typography
          component={'span'}
          variant="h6"
          color="text.primary"
          sx={{ flexGrow: 1, fontWeight: 700, ml: 1 }}
        >
          Flare Dienst
        </Typography>
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box marginLeft={4}>
          <NavItem
            title={'Block explorer'}
            id={'block-explorer-menu'}
            items={blockExplorerPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'FTSO monitor'}
            id={'ftso-monitor-menu'}
            items={ftsoMonitorPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Validators'}
            id={'validators-menu'}
            items={validatorPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={2}>
          <ThemeModeToggler />
        </Box>
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

export default Topbar;
