import Box from '@mui/material/Box';

import { Typography, useTheme } from '@mui/material';
import ThemeModeToggler from 'components/ThemeModeToggler';
import NavItem from './components/NavItem';
//@ts-ignore
import FlareDienst from 'assets/icons/FlareDienst';

interface Props {
  pages: {
    blockExplorer: Array<PageItem>;
    ftsoMonitor: Array<PageItem>;
    validators: Array<PageItem>;
  };
}

const SidebarNav = ({ pages }: Props): JSX.Element => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const { blockExplorer: blockExplorerPages, ftsoMonitor: ftsoMonitorPages, validators: validatorPages } =
    pages;

  return (
    <Box>
      <Box width={1} paddingLeft={2} paddingRight={1} paddingY={1}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          title="flareDienst"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <FlareDienst
              backgroundColor={mode === 'light' ? '#FFFFFF' : '#EEEEEF'}
            />
            <Typography
              component={'span'}
              variant="h6"
              color="text.primary"
              sx={{ flexGrow: 1, fontWeight: 700, ml: 1 }}
            >
              Flare Dienst
            </Typography>
          </Box>
          <Box>
            <ThemeModeToggler />
          </Box>
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        <Box>
          <NavItem title={'Block explorer'} items={blockExplorerPages} />
        </Box>
        <Box>
          <NavItem title={'Ftso monitor'} items={ftsoMonitorPages} />
        </Box>
        <Box>
          <NavItem title={'Validators'} items={validatorPages} />
        </Box>
      </Box>
    </Box>
  );
};

export default SidebarNav;
