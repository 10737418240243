import CloudIcon from '@mui/icons-material/Cloud';
import GppGoodIcon from '@mui/icons-material/GppGood';
import SettingsIcon from '@mui/icons-material/Settings';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

const mock = [
  {
    title: 'Cloud hosted services',
    subtitle:
      'Flare Dienst runs all its own nodes, backup nodes and validator in the cloud to ensure our data provider service has the highest level of availability.',
    icon: <CloudIcon/>,
  },
  {
    title: 'Trusted data sources',
    subtitle:
      'We are committed to sourcing data only from the most reliable exchanges. Each source has been extensively verified and validated before use.',
    icon: <GppGoodIcon />,
  },
  {
    title: 'Proprietary algorithm',
    subtitle:
      'We developed our own algorithm in-house, free from third party involvement. Our algorithm is designed to provide accurate prices while maintaining appropriate safeguards.',
    icon: <SettingsIcon/>,
  },
];

const Services = (): JSX.Element => {
  const theme = useTheme();
  return (
    <Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={60}
                  height={60}
                  marginBottom={2}
                  bgcolor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icon}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 500 }}
                  align={'center'}
                >
                  {item.title}
                </Typography>
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Services;
