import { SvgIcon } from '@mui/material';

export default function FlareDienst(props) {
  return (
    <SvgIcon viewBox="0 0 67.733333 67.733333">
      <circle
        fill={props.backgroundColor}
        opacity={props.opacity}
        cx="33.866665"
        cy="33.866665"
        r="33.866665"
      />
      <path
        fill="#000"
        d="M 33.808131,4.6115973 C 25.955563,4.5877295 18.168006,7.9108561 12.752646,13.59645 c 9.093587,0.04283 18.191204,-0.08908 27.28206,0.07266 5.847862,0.225136 11.661047,3.223098 14.5398,8.442518 3.470821,6.152232 3.505857,13.883638 1.178861,20.437645 -2.142464,5.844089 -7.483038,10.637415 -13.837431,11.099932 -1.612797,-0.01878 -4.578058,1.01992 -3.848682,-1.525133 0,-3.332005 0,-6.664018 0,-9.996023 3.080793,0.171522 6.908048,-0.540196 8.11257,-3.804874 1.145805,-3.502288 1.376155,-7.932529 -1.108685,-10.929307 -2.649328,-2.867355 -6.79221,-2.339171 -10.333156,-2.384702 0.04162,9.587772 0.08332,19.175548 0.124941,28.763313 -3.370913,0 -6.741833,0 -10.112746,0 0,-4.515479 0,-9.030955 0,-13.546431 -2.140243,0 -4.280488,0 -6.420731,0 -1.347556,-2.77391 -2.69512,-5.547809 -4.042677,-8.321719 3.487799,0 6.975609,0 10.463408,0 0,-2.377629 0,-4.755268 0,-7.132898 -4.597562,0 -9.195116,0 -13.792681,0 C 10.119607,22.966961 9.2817164,21.162497 8.4438265,19.35803 2.5516322,29.378905 3.5344286,42.864535 10.834835,51.91189 17.598272,60.737658 29.648127,64.977292 40.451953,62.352492 51.865417,59.855894 61.132815,49.88166 62.759194,38.310192 64.572997,27.367638 59.461434,15.705463 50.200167,9.6061651 45.398865,6.3465454 39.610783,4.5881519 33.808131,4.6115973 Z"
      />
    </SvgIcon>
  );
}
